@if $decoratorsEnabled {
    @each $decorator, $declaration in $decorators {
        @each $property, $value in $declaration {
            [#{$decoratorsAttribute}~="#{$decorator}"] {
                #{$property}: $value;
            }
        }
    }
    @if $decoratorsBreakpoints {
        @each $breakpoint, $resolution in $breakpoints {
            @media (max-width: $resolution) {
                @each $decorator, $declaration in $decorators {
                    @each $property, $value in $declaration {
                        [#{$decoratorsAttribute}~="@#{$breakpoint}=#{$decorator}"] {
                            #{$property}: $value;
                        }
                    }
                }
            }
        }
    }
}





@keyframes pulse_1__1 {
	100% {
		transform: scale(2);
        opacity: 0;
	}
}

@keyframes marquee_1__1 {
    to {
        transform: translateX(-110%)
    }
}





$colors: (
    "tt": transparent,
    "we": white,
    "bk": black,
    "rd": red,
    "yw": yellow,
    "bip": #c00418,
    "facebook": #1B5BAF,

    "main-1": #ff9900,
    "main-2": black,
    "main-3": black,
    "main-4": black,
    "main-5": black,
    "main-6": black,
    "main-7": black,

    "1": #f2f2f2,
    "2": rgba(0, 0, 0, .2),
    "3": #cccccc,
    "4": rgba(255, 255, 255, .5),
    "5": rgba(255, 255, 255, .2),
    "6": rgba(0, 0, 0, .7),
    "7": rgba(0, 0, 0, .9),
    "8": #dddddd,
    "9": rgba(255, 255, 255, .8),
    "10": rgba(0, 0, 0, .3),
    "11": rgba(255, 255, 255, .3),
    "12": #e2e2e2,
    "13": #222222,
    "14": #333333,
    "15": #A20623,
    "16": #aaaaaa,
    "17": #555555,
    "18": #43b4ae,
    "19": #d6d6d6,
    "20": #e8e8e8,
);

$fonts: (
    "main-lt": "Montserrat-Light",
    "main-rr": "Montserrat-Regular",
    "main-bd": "Montserrat-Bold",
);

:root {
    @each $name, $color in $colors {
        --color-#{$name}: #{$color};
    }
    @each $name, $font in $fonts {
        --font-#{$name}: #{$font};
    }
}

@each $name, $color in $colors {
    [#{$decoratorsAttribute}~="bd-cr=#{$name}"] {
        background-color: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="br-cr=#{$name}"] {
        border-color: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="co=#{$name}"] {
        color: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="se=#{$name}"] {
        stroke: var(--color-#{$name});
    }
    [#{$decoratorsAttribute}~="fl=#{$name}"] {
        fill: var(--color-#{$name});
    }
}

@each $name, $font in $fonts {
    [#{$decoratorsAttribute}~="ft-fy=#{$name}"] {
        font-family: var(--font-#{$name});
    }
}





[data-d~="bd=1"] { background: linear-gradient(180deg, var(--color-8) 0%, var(--color-1) 30%, var(--color-1) 100%) }
[data-d~="bd=2"] { background: linear-gradient(0deg, var(--color-8) 0%, var(--color-1) 30%, var(--color-1) 100%) }

[data-d~="bd-ie=texture=1"] { background-image: url("/resources/textures/Texture_1__1.webp") }
[data-d~="bd-ie=texture=2"] { background-image: url("/resources/textures/Texture_2__1.webp") }

[data-d~="bd-rt=no-rt"] { background-repeat: no-repeat }

[data-d~="bd-se=cr"] { background-size: cover }

[data-d~="bd-pn=cr"] { background-position: center }

[data-d~="fr=gyse(1)"] { filter: grayscale(1) }




[data-d~="br=ne"] { border: none }

[data-d~="br-se=sd"] { border-style: solid }
[data-d~="br-se=dd"] { border-style: dashed }
[data-d~="br-tp-se=sd"] { border-top-style: solid }
[data-d~="br-tp-se=dd"] { border-top-style: dashed }
[data-d~="br-rt-se=sd"] { border-right-style: solid }
[data-d~="br-bm-se=sd"] { border-bottom-style: solid }
[data-d~="br-lt-se=sd"] { border-left-style: solid }

[data-d~="br-ce=ce"] { border-collapse: collapse }

[data-d~="br-sg=2px"] { border-spacing: 2px }

[data-d~="br-wh=1px"] { border-width: 1px }
[data-d~="br-wh=2px"] { border-width: 2px }

[data-d~="br-tp-wh=15px"] { border-top-width: 15px }
[data-d~="br-rt-wh=10px"] { border-right-width: 10px }
[data-d~="br-bm-wh=15px"] { border-bottom-width: 15px }
[data-d~="br-bm-wh=10px"] { border-bottom-width: 10px }
[data-d~="br-lt-wh=10px"] { border-left-width: 10px }
[data-d~="br-lt-wh=15px"] { border-left-width: 15px }

[data-d~="br-tp-cr=tt"] { border-top-color: var(--color-tt) }

[data-d~="br-rt-cr=tt"] { border-right-color: var(--color-tt) }
[data-d~="br-rt-cr=we"] { border-right-color: var(--color-we) }

[data-d~="br-bm-cr=tt"] { border-bottom-color: var(--color-tt) }
[data-d~="br-bm-cr=we"] { border-bottom-color: var(--color-we) }

[data-d~="br-lt-cr=tt"] { border-left-color: var(--color-tt) }
[data-d~="br-lt-cr=we"] { border-left-color: var(--color-we) }
[data-d~="br-lt-cr=main-1"] { border-left-color: var(--color-main-1) }
[data-d~="br-lt-cr=main-2"] { border-left-color: var(--color-main-2) }

[data-d~="br-rs=2px"] { border-radius: 2px }
[data-d~="br-rs=3px"] { border-radius: 3px }
[data-d~="br-rs=6px"] { border-radius: 6px }
[data-d~="br-rs=100%"] { border-radius: 100% }
[data-d~="br-rs=15px"] { border-radius: 15px }
[data-d~="br-tp-lt-rs=3px"] { border-top-left-radius: 3px }
[data-d~="br-bm-lt-rs=3px"] { border-bottom-left-radius: 3px }

[data-d~="br-tp-lt-rs=6px"] { border-top-left-radius: 6px }
[data-d~="br-tp-rt-rs=6px"] { border-top-right-radius: 6px }

[data-d~=":nt(:ft-cd)=br-tp-se=sd"]:not(:first-child) { border-top-style: solid }



[data-d~="bx-sw=1"] { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2) }
[data-d~="bx-sw=2"] { box-shadow: 0px 3px 0px 0px rgba(255, 255, 255, .5) }



[data-d~="gd-te=1"] { grid-template: auto auto 1fr auto / 100% }
[data-d~="gd-te=2"] { grid-template: auto 1fr / 100% }
[data-d~="gd-te=3"] { grid-template: auto 1fr auto / 100% }
[data-d~="gd-te=4"] { grid-template: auto auto auto 1fr auto / 100% }

[data-d~="gd-te-cs=1fr"] { grid-template-columns: 1fr }
[data-d~="gd-te-cs=100%"] { grid-template-columns: 100% }

[data-d~="gd-te-rs=1fr"] { grid-template-rows: 1fr }
[data-d~="gd-te-rs=auto1fr"] { grid-template-rows: auto 1fr }

[data-d~="gd-te-as=1"] { grid-template-areas: "full" }

[data-d~="gd-aa=1"] { grid-area: full }

[data-d~="gd-te=1"] { grid-template: auto 1fr / 100%; }



[data-d~="at-ro=30"] { aspect-ratio: 100 / 30 }
[data-d~="at-ro=40"] { aspect-ratio: 100 / 40 }
[data-d~="at-ro=60"] { aspect-ratio: 100 / 60 }
[data-d~="at-ro=70"] { aspect-ratio: 100 / 70 }
[data-d~="at-ro=80"] { aspect-ratio: 100 / 80 }
[data-d~="at-ro=100"] { aspect-ratio: 100 / 100 }



[data-d~="wh=il"] { width: initial }
[data-d~="wh=0"] { width: 0 }
[data-d~="wh=14%"] { width: 14% }
[data-d~="wh=25%"] { width: 25% }
[data-d~="wh=100%"] { width: 100% }
[data-d~="wh=150%"] { width: 150% }
[data-d~="wh=1px"] { width: 1px }
[data-d~="wh=2px"] { width: 2px }
[data-d~="wh=8px"] { width: 8px }
[data-d~="wh=11px"] { width: 11px }
[data-d~="wh=13px"] { width: 13px }
[data-d~="wh=15px"] { width: 15px }
[data-d~="wh=16px"] { width: 16px }
[data-d~="wh=20px"] { width: 20px }
[data-d~="wh=25px"] { width: 25px }
[data-d~="wh=30px"] { width: 30px }
[data-d~="wh=35px"] { width: 35px }
[data-d~="wh=40px"] { width: 40px }
[data-d~="wh=45px"] { width: 45px }
[data-d~="wh=50px"] { width: 50px }
[data-d~="wh=60px"] { width: 60px }
[data-d~="wh=80px"] { width: 80px }
[data-d~="wh=100px"] { width: 100px }
[data-d~="wh=120px"] { width: 120px }
[data-d~="wh=140px"] { width: 140px }
[data-d~="wh=150px"] { width: 150px }
[data-d~="wh=300px"] { width: 300px }
[data-d~="wh=400px"] { width: 400px }
[data-d~="wh=500px"] { width: 500px }
[data-d~="wh=600px"] { width: 600px }
[data-d~="wh=800px"] { width: 800px }
[data-d~="wh=10000px"] { width: 10000px }

[data-d~="mn-wh=20px"] { min-width: 20px }
[data-d~="mn-wh=25px"] { min-width: 25px }
[data-d~="mn-wh=35px"] { min-width: 35px }
[data-d~="mn-wh=40px"] { min-width: 40px }
[data-d~="mn-wh=100px"] { min-width: 100px }
[data-d~="mn-wh=140px"] { min-width: 140px }
[data-d~="mn-wh=155x"] { min-width: 155px }
[data-d~="mn-wh=300px"] { min-width: 300px }
[data-d~="mn-wh=400px"] { min-width: 400px }
[data-d~="mn-wh=100%"] { min-width: 100% }

[data-d~="mx-wh=120px"] { max-width: 120px }
[data-d~="mx-wh=150px"] { max-width: 150px }
[data-d~="mx-wh=700px"] { max-width: 700px }
[data-d~="mx-wh=90%"] { max-width: 90% }
[data-d~="mx-wh=100%"] { max-width: 100% }



[data-d~="ht=il!"] { height: initial !important }
[data-d~="ht=il"] { height: initial }
[data-d~="ht=ao"] { height: auto }
[data-d~="ht=0"] { height: 0 }
[data-d~="ht=100%"] { height: 100% }
[data-d~="ht=150%"] { height: 150% }
[data-d~="ht=1px"] { height: 1px }
[data-d~="ht=2px"] { height: 2px }
[data-d~="ht=2px!"] { height: 2px !important }
[data-d~="ht=8px"] { height: 8px }
[data-d~="ht=10px"] { height: 10px }
[data-d~="ht=11px"] { height: 11px }
[data-d~="ht=13px"] { height: 13px }
[data-d~="ht=16px"] { height: 16px }
[data-d~="ht=20px"] { height: 20px }
[data-d~="ht=25px"] { height: 25px }
[data-d~="ht=30px"] { height: 30px }
[data-d~="ht=35px"] { height: 35px }
[data-d~="ht=40px"] { height: 40px }
[data-d~="ht=50px"] { height: 50px }
[data-d~="ht=60px"] { height: 60px }
[data-d~="ht=80px"] { height: 80px }
[data-d~="ht=100px"] { height: 100px }
[data-d~="ht=200px"] { height: 200px }
[data-d~="ht=250px"] { height: 250px }
[data-d~="ht=380px"] { height: 380px }
[data-d~="ht=100%"] { height: 100% }

[data-d~="mn-ht=20px"] { min-height: 20px }
[data-d~="mn-ht=30px"] { min-height: 30px }
[data-d~="mn-ht=35px"] { min-height: 35px }
[data-d~="mn-ht=40px"] { min-height: 40px }
[data-d~="mn-ht=50px"] { min-height: 50px }
[data-d~="mn-ht=60px"] { min-height: 60px }
[data-d~="mn-ht=80px"] { min-height: 80px }
[data-d~="mn-ht=100px"] { min-height: 100px }
[data-d~="mn-ht=100%"] { min-height: 100% }
[data-d~="mn-ht=100vh"] { min-height: 100vh }

[data-d~="mx-ht=200px"] { max-height: 200px }
[data-d~="mx-ht=500px"] { max-height: 500px }
[data-d~="mx-ht=100%"] { max-height: 100% }





[data-d~="ft-se=1px"] { font-size: 1px }
[data-d~="ft-se=11rem"] { font-size: 11rem }
[data-d~="ft-se=12rem"] { font-size: 12rem }
[data-d~="ft-se=13rem"] { font-size: 13rem }
[data-d~="ft-se=14rem"] { font-size: 14rem }
[data-d~="ft-se=16rem"] { font-size: 16rem }
[data-d~="ft-se=18rem"] { font-size: 18rem }
[data-d~="ft-se=20rem"] { font-size: 20rem }
[data-d~="ft-se=22rem"] { font-size: 22rem }
[data-d~="ft-se=24rem"] { font-size: 24rem }
[data-d~="ft-se=26rem"] { font-size: 26rem }
[data-d~="ft-se=28rem"] { font-size: 28rem }
[data-d~="ft-se=30rem"] { font-size: 30rem }
[data-d~="ft-se=40rem"] { font-size: 40rem }
[data-d~="ft-se=50rem"] { font-size: 50rem }
[data-d~="ft-se=70rem"] { font-size: 70rem }

[data-d~="lr-sg=1rem"] { letter-spacing: 1rem }
[data-d~="lr-sg=2rem"] { letter-spacing: 2rem }
[data-d~="lr-sg=3rem"] { letter-spacing: 3rem }

[data-d~="le-ht=0"] { line-height: 0 }
[data-d~="le-ht=.8"] { line-height: .8 }
[data-d~="le-ht=1"] { line-height: 1 }
[data-d~="le-ht=1.2"] { line-height: 1.2 }
[data-d~="le-ht=1.8"] { line-height: 1.8 }

[data-d~="tt-an=lt"] { text-align: left }
[data-d~="tt-an=rt"] { text-align: right }
[data-d~="tt-an=cr"] { text-align: center }

[data-d~="ft-wt=nl"] { font-weight: normal }
[data-d~="ft-wt=bd"] { font-weight: bold }

[data-d~="tt-tm=ue"] { text-transform: uppercase }

[data-d~="tt-dn=ue"] { text-decoration: underline }
[data-d~="tt-dn=le-th"] { text-decoration: line-through }

[data-d~="wd-bk=bk-al"] { word-break: break-all }
[data-d~="wd-bk=kp-al"] { word-break: keep-all }

[data-d~="we-se=nowp"] { white-space: nowrap }

[data-d~="or=1"] { order: 1 }
[data-d~="or=2"] { order: 2 }
[data-d~="or=3"] { order: 3 }



[data-d~="pn=re"] { position: relative }
[data-d~="pn=ae"] { position: absolute }
[data-d~="pn=fd"] { position: fixed }
[data-d~="pn=sy"] { position: sticky }

[data-d~="tp=map"] { top: 65% }
[data-d~="tp=-10px"] { top: -10px }
[data-d~="tp=0"] { top: 0 }
[data-d~="tp=20px"] { top: 20px }
[data-d~="tp=100px"] { top: 100px }
[data-d~="tp=140px"] { top: 140px }
[data-d~="tp=-5%"] { top: -5% }
[data-d~="tp=100%"] { top: 100% }

[data-d~="rt=-15px"] { right: -15px }
[data-d~="rt=-10px"] { right: -10px }
[data-d~="rt=-5px"] { right: -5px }
[data-d~="rt=0"] { right: 0 }
[data-d~="rt=20px"] { right: 20px }
[data-d~="rt=40px"] { right: 40px }
[data-d~="rt=40%"] { right: 40% }

[data-d~="bm=0"] { bottom: 0 }
[data-d~="bm=10px"] { bottom: 10px }
[data-d~="bm=20px"] { bottom: 20px }
[data-d~="bm=50px"] { bottom: 50px }
[data-d~="bm=60px"] { bottom: 60px }
[data-d~="bm=70px"] { bottom: 70px }
[data-d~="bm=15%"] { bottom: 15% }

[data-d~="lt=map"] { left: 55% }
[data-d~="lt=0"] { left: 0 }
[data-d~="lt=10px"] { left: 10px }
[data-d~="lt=20px"] { left: 20px }
[data-d~="lt=60px"] { left: 60px }
[data-d~="lt=-5%"] { left: -5% }
[data-d~="lt=-10px"] { left: -10px }
[data-d~="lt=30%"] { left: 30% }



[data-d~="lt-se-te=il"] { list-style-type: initial }
[data-d~="lt-se-te=ne"] { list-style-type: none }
[data-d~="lt-se-te=ne!"] { list-style-type: none !important }
[data-d~="lt-se-te=dc"] { list-style-type: disc }

[data-d~="ow=hn"] { overflow: hidden }

[data-d~="ow-y=ao"] { overflow-y: auto }
[data-d~="ow-x=ao"] { overflow-x: auto }

[data-d~="oe=ne"] { outline: none }

[data-d~="cu=pr"] { cursor: pointer }

[data-d~="ot-ft=cr"] { object-fit: cover }

[data-d~="ot-pn=tp"] { object-position: top }
[data-d~="ot-pn=rt"] { object-position: right }
[data-d~="ot-pn=bm"] { object-position: bottom }
[data-d~="ot-pn=lt"] { object-position: left }
[data-d~="ot-pn=cr"] { object-position: center }

[data-d~="dy=ne"] { display: none }

[data-d~="ft=lt"] { float: left }

[data-d~="oy=0"] { opacity: 0 }

[data-d~="vy=hn"] { visibility: hidden }

[data-d~="pr-es=ne"] { pointer-events: none }
[data-d~="pr-es=al"] { pointer-events: all }

[data-d~="vl-an=tp"] { vertical-align: top }
[data-d~="vl-an=me"] { vertical-align: middle }

[data-d~="cr=bh"] { clear: both }

[data-d~="ie-rg=-wt-oe-ct"] { image-rendering: -webkit-optimize-contrast }


[data-d~="z-ix=1"] { z-index: 1 }
[data-d~="z-ix=2"] { z-index: 2 }
[data-d~="z-ix=100"] { z-index: 100 }
[data-d~="z-ix=200"] { z-index: 200 }
[data-d~="z-ix=300"] { z-index: 300 }
[data-d~="z-ix=400"] { z-index: 400 }
[data-d~="z-ix=500"] { z-index: 500 }
[data-d~="z-ix=1000"] { z-index: 1000 }
[data-d~="z-ix=2000"] { z-index: 2000 }
[data-d~="z-ix=10000"] { z-index: 10000 }
[data-d~="z-ix=20000"] { z-index: 20000 }
[data-d~="z-ix=30000"] { z-index: 30000 }
[data-d~="z-ix=40000"] { z-index: 40000 }
[data-d~="z-ix=50000"] { z-index: 50000 }
[data-d~="z-ix=60000"] { z-index: 60000 }



[data-d~="pg=il!"] { padding: initial !important }
[data-d~="pg=0"] { padding: 0 }
[data-d~="pg=0!"] { padding: 0 !important }
[data-d~="pg=1px"] { padding: 1px }
[data-d~="pg=2px"] { padding: 2px }
[data-d~="pg=3px"] { padding: 3px }
[data-d~="pg=5px"] { padding: 5px }
[data-d~="pg=10px"] { padding: 10px }
[data-d~="pg=15px"] { padding: 15px }
[data-d~="pg=20px"] { padding: 20px }
[data-d~="pg=30px"] { padding: 30px }
[data-d~="pg=40px"] { padding: 40px }
[data-d~="pg=5px10px"] { padding: 5px 10px }
[data-d~="pg=40px0"] { padding: 40px 0 }
[data-d~="pg=10px5px"] { padding: 10px 5px }
[data-d~="pg=5px20px"] { padding: 5px 20px }
[data-d~="pg=10px20px"] { padding: 10px 20px }
[data-d~="pg=15px20px"] { padding: 15px 20px }

[data-d~="pg-tp=0"] { padding-top: 0 }
[data-d~="pg-tp=5px"] { padding-top: 5px }
[data-d~="pg-tp=10px"] { padding-top: 10px }
[data-d~="pg-tp=20px"] { padding-top: 20px }
[data-d~="pg-tp=30px"] { padding-top: 30px }
[data-d~="pg-tp=40px"] { padding-top: 40px }
[data-d~="pg-tp=60px"] { padding-top: 60px }

[data-d~="pg-rt=5px"] { padding-right: 5px }
[data-d~="pg-rt=10px"] { padding-right: 10px }
[data-d~="pg-rt=20px"] { padding-right: 20px }
[data-d~="pg-rt=30px"] { padding-right: 30px }
[data-d~="pg-rt=40px"] { padding-right: 40px }

[data-d~="pg-bm=0"] { padding-bottom: 0 }
[data-d~="pg-bm=2px"] { padding-bottom: 2px }
[data-d~="pg-bm=3px"] { padding-bottom: 3px }
[data-d~="pg-bm=5px"] { padding-bottom: 5px }
[data-d~="pg-bm=10px"] { padding-bottom: 10px }
[data-d~="pg-bm=20px"] { padding-bottom: 20px }
[data-d~="pg-bm=30px"] { padding-bottom: 30px }
[data-d~="pg-bm=40px"] { padding-bottom: 40px }
[data-d~="pg-bm=50px"] { padding-bottom: 50px }
[data-d~="pg-bm=60px"] { padding-bottom: 60px }
[data-d~="pg-bm=60px"] { padding-bottom: 60px }

[data-d~="pg-lt=0"] { padding-left: 0 }
[data-d~="pg-lt=1px"] { padding-left: 1px }
[data-d~="pg-lt=5px"] { padding-left: 5px }
[data-d~="pg-lt=10px"] { padding-left: 10px }
[data-d~="pg-lt=15px"] { padding-left: 15px }
[data-d~="pg-lt=20px"] { padding-left: 20px }
[data-d~="pg-lt=25px"] { padding-left: 25px }
[data-d~="pg-lt=30px"] { padding-left: 30px }

[data-d~="mn=-10px"] { margin: -10px }

[data-d~="mn-tp=-40px"] { margin-top: -40px }
[data-d~="mn-tp=-30px"] { margin-top: -30px }
[data-d~="mn-tp=-20px"] { margin-top: -20px }
[data-d~="mn-tp=0"] { margin-top: 0 }
[data-d~="mn-tp=1px"] { margin-top: 1px }
[data-d~="mn-tp=2px"] { margin-top: 2px }
[data-d~="mn-tp=3px"] { margin-top: 3px }
[data-d~="mn-tp=5px"] { margin-top: 5px }
[data-d~="mn-tp=10px"] { margin-top: 10px }
[data-d~="mn-tp=20px"] { margin-top: 20px }
[data-d~="mn-tp=30px"] { margin-top: 30px }
[data-d~="mn-tp=40px"] { margin-top: 40px }
[data-d~="mn-tp=60px"] { margin-top: 60px }
[data-d~="mn-tp=200px"] { margin-top: 200px }
[data-d~=":nt(:ft-cd)=mn-tp=5px"]:not(:first-child) { margin-top: 5px }
[data-d~=":nt(:ft-cd)=mn-tp=10px"]:not(:first-child) { margin-top: 10px }
[data-d~=":nt(:ft-cd)=mn-tp=20px"]:not(:first-child) { margin-top: 20px }

[data-d~="mn-rt=10px"] { margin-right: 10px }
[data-d~="mn-rt=20px"] { margin-right: 20px }
[data-d~="mn-rt=40px"] { margin-right: 40px }

[data-d~="mn-bm=0"] { margin-bottom: 0 }
[data-d~="mn-bm=-60px"] { margin-bottom: -60px }
[data-d~="mn-bm=5px"] { margin-bottom: 5px }
[data-d~="mn-bm=10px"] { margin-bottom: 10px }
[data-d~="mn-bm=20px"] { margin-bottom: 20px }
[data-d~="mn-bm=50px"] { margin-bottom: 50px }

[data-d~="mn-lt=0"] { margin-left: 0 }
[data-d~="mn-lt=2px"] { margin-left: 2px }
[data-d~="mn-lt=5px"] { margin-left: 5px }
[data-d~="mn-lt=10px"] { margin-left: 10px }
[data-d~="mn-lt=15px"] { margin-left: 15px }
[data-d~="mn-lt=20px"] { margin-left: 20px }
[data-d~="mn-lt=34px"] { margin-left: 34px }
[data-d~="mn-lt=40px"] { margin-left: 40px }
[data-d~="mn-lt=80px"] { margin-left: 80px }



[data-d~="tn-dn=250ms"] { transition-duration: 250ms }
[data-d~="tn-dn=500ms"] { transition-duration: 500ms }
[data-d~="tn-dn=6000ms"] { transition-duration: 6000ms }

[data-d~="tn-tg-fn=ee-in-ot"] { transition-timing-function: ease-in-out }

[data-d~="tm=teX(-100vw)"] { transform: translateX(-100vw) }
[data-d~="tm=teX(-100%)"] { transform: translateX(-100%) }
[data-d~="tm=teX(100%)"] { transform: translateX(100%) }
[data-d~="tm=teX(100vw)"] { transform: translateX(100vw) }

[data-d~="tm=teY(-30px)"] { transform: translateY(-30px) }

[data-d~="re=-45deg"] { rotate: -45deg }
[data-d~="re=45deg"] { rotate: 45deg }
[data-d~="re=90deg"] { rotate: 90deg }
[data-d~="re=180deg"] { rotate: 180deg }

[data-d~="tm=re(90deg)"] { transform: rotate(90deg) }

[data-d~="te-lt=fd"] { table-layout: fixed }

[data-d~="an-in-ct=ie"] { animation-iteration-count: infinite }

[data-d~="an-dn=2s"] { animation-duration: 2s }
[data-d~="an-dn=50s"] { animation-duration: 50s }

[data-d~="an-ne=1"] { animation-name: pulse_1__1 }
[data-d~="an-ne=2"] { animation-name: marquee_1__1 }

[data-d~="an-tg-fn=lr"] { animation-timing-function: linear }

[data-d~="fx-sk=0"] { flex-shrink: 0 }

















[data-d~=":hr->wh"] {
    &:hover {
        [data-d~=":hr->wh=100%"] { width: 100% }
    }
}

[data-d~=":fs-wn->wh"] {
    &:focus-within {
        [data-d~=":fs-wn->wh=100%"] { width: 100% }
    }
}


[data-d~=":hr->co"] {
    &:hover {
        [data-d~=":hr->co=we"] { color: var(--color-we) }
        [data-d~=":hr->co=bk"] { color: var(--color-bk) }
    }
}

[data-d~=":fs-wn->co"] {
    &:focus-within {
        [data-d~=":fs-wn->co=we"] { color: var(--color-we) }
        [data-d~=":fs-wn->co=bk"] { color: var(--color-bk) }
    }
}


[data-d~=":hr->bd-cr"] {
    &:hover {
        [data-d~=":hr->bd-cr=bk"] { background-color: var(--color-bk) }
        [data-d~=":hr->bd-cr=1"] { background-color: var(--color-1) }
    }
}

[data-d~=":fs-wn->bd-cr"] {
    &:focus-within {
        [data-d~=":fs-wn->bd-cr=bk"] { background-color: var(--color-bk) }
    }
}


[data-d~=":hr->tt-dn"] {
    &:hover {
        [data-d~=":hr->tt-dn=ue"] { text-decoration: underline }
    }
}

[data-d~=":fs-wn->tt-dn"] {
    &:focus-within {
        [data-d~=":fs-wn->tt-dn=ue"] { text-decoration: underline }
    }
}


[data-d~=":hr->se"] {
    &:hover {
        [data-d~=":hr->se=1.1"] { scale: 1.1 }
    }
}

[data-d~=":fs-wn->se"] {
    &:focus-within {
        [data-d~=":fs-wn->se=1.1"] { scale: 1.1 }
    }
}


[data-d~=":hr->oy"] {
    &:hover {
        [data-d~=":hr->oy=1"] { opacity: 1 }
    }
}

[data-d~=":fs-wn->oy"] {
    &:focus-within {
        [data-d~=":fs-wn->oy=1"] { opacity: 1 }
    }
}


[data-d~=":hr->tm=teY"] {
	&:hover {
		[data-d~=":hr->tm=teY(0)"] { transform: translateY(0) }
	}
}

[data-d~=":fs-wn->tm=teY"] {
	&:focus-within {
		[data-d~=":fs-wn->tm=teY(0)"] { transform: translateY(0) }
	}
}


[data-d~=":hr->pr-es"] {
	&:hover {
		[data-d~=":hr->pr-es=ao"] { pointer-events: auto }
	}
}

[data-d~=":fs-wn->pr-es"] {
	&:focus-within {
		[data-d~=":fs-wn->pr-es=ao"] { pointer-events: auto }
	}
}


[data-d~=":hr=tt-dn=ue"]:hover { text-decoration: underline }

[data-d~="::pr=co=we"]::placeholder { color: var(--color-we) }
[data-d~="::pr=co=bk"]::placeholder { color: var(--color-bk) }

[data-d~="::-wt-sr=oy=0"]::-webkit-scrollbar { opacity: 0 }
[data-d~="::-wt-sr=dy=ne"]::-webkit-scrollbar { display: none }

[data-d~=":hr=an-py-se=pd"]:hover { animation-play-state: paused }
[data-d~=":fs-wn=an-py-se=pd"]:focus-within { animation-play-state: paused }

[data-d~=":fs-wn=tm=teX(0)"]:focus-within { transform: translateX(0) }

/**
 * Harmonogram wywozu odpadów
 */
.choices {
    z-index: 9999;
    color: #000;
}





