@mixin a($e) { @extend [data-a~="#{$e}"]; }
@mixin d($e) { @extend [data-d~="#{$e}"]; }
@mixin g($e) { @extend [data-g~="#{$e}"]; }





[data-p~="t:1"] {
	@include d("ft-fy=main-bd");
	@include d("ft-se=30rem");
	@include d("lr-sg=1rem");
	@include d("le-ht=1");

	@include d("@2000=ft-se=26rem");
	@include d("@1450=ft-se=24rem");
	@include d("@1300=ft-se=22rem");
	@include d("@1000=ft-se=20rem");
}

[data-p~="t:2"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=12rem");
	@include d("lr-sg=1rem");
	@include d("le-ht=1.2");

	@include d("@1600=ft-se=11rem");
}

[data-p~="t:3"] {
	@include d("ft-fy=main-bd");
	@include d("ft-se=20rem");
	@include d("lr-sg=1rem");
	@include d("le-ht=1");

	@include d("@1600=ft-se=18rem");
	@include d("@1000=ft-se=16rem");
}



[data-p~="content"] {
	@include d("ft-fy=main-rr");
	@include d("ft-se=14rem");
	@include d("le-ht=1.8");

	@include d("@2000=ft-se=12rem");

	a {
		color: var(--color-15);

		&:hover {
			@include d("tt-dn=ue");
		}
	}
	p:not(:first-child) {
		@include d("mn-tp=10px");
	}
	ul {
		@include d("lt-se-te=il");
		@include d("pg-lt=15px");
	}
	> ul:not(:first-child) {
		@include d("mn-tp=10px");
	}
	ol {
		@include d("lt-se-te=il");
		@include d("pg-lt=15px");
	}
	img {
		@include d("mx-wh=100%");
	}
	iframe {
		@include d("mx-wh=100%");
	}
	table {
		@include d("mx-wh=100%");
	}
}



// Checkbox
[data-p~="1:1"] {
	&:hover,
	&:focus-within,
	&:checked {
		+ [data-p~="1:2"] {

			[data-p~="1:3"] {
				background-color: var(--color-bk);

				[data-p~="1:4"] {
					color: var(--color-we);
				}
			}
		}
	}
}

// Breadcrumb
[data-p~="2:1"] {
	&:nth-child(even) {
		[data-p~="2:2"] {
			background-color: var(--color-1);
		}
		[data-p~="2:3"] {
			border-left-color: var(--color-1);
		}
	}
	&:nth-child(odd) {
		[data-p~="2:2"] {
			background-color: var(--color-12);
		}
		[data-p~="2:3"] {
			border-left-color: var(--color-12);
		}
	}
}

// Carousel
[data-p~="3:1"] {
    background-color: var(--color-5) !important;

    > span {
        background-color: var(--color-we) !important;
    }
}

[data-p~="3:2"] {
    background-color: var(--color-2) !important;

    > span {
        background-color: var(--color-bk) !important;
    }
}

[data-p~="4:1"] {
	right: initial !important;
	top: initial !important;
	transform: initial !important;

	> span {
		background-color: transparent !important;
		border-style: solid !important;
		border-color: var(--color-we) !important;
		border-width: 2px !important;
		width: 16px !important;
		height: 16px !important;
		opacity: 1 !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		margin: 2px !important;

		// &:not(:first-child) {
		// 	margin-top: 5px !important;
		// }

		&.swiper-pagination-bullet-active::before {
			content: "" !important;
			background-color: var(--color-we) !important;
			border-radius: 100% !important;
			width: 8px !important;
			height: 8px !important;
		}
	}
}

// Carousel
[data-p~="5:1"] {
    background-color: var(--color-5) !important;

    > span {
        background-color: var(--color-we) !important;
    }
}

// Navigation subcategories
[data-p~="6:1"] {
	&:nth-child(-n+4) {
		[data-p~="6:2"] {
			right: initial;
			left: 0;
		}
		[data-p~="6:3"] {
			right: initial;
			left: 20px;
		}
	}
}

// Languages
[data-p~="7:1"] {
	display: flex !important;
	align-items: center !important;

	a {
		display: flex !important;
		align-items: center !important;
		padding: 2px !important;

		img {
			border: 1px solid var(--color-3) !important;
			width: 20px !important;
			height: 15px !important;
			padding: 1px !important;
			margin: initial !important;
			box-sizing: content-box !important;
		}
	}
}





[data-p~="8:1"] {
	* {
		pointer-events: none;
	}
	[data-p~="8:2"] {
		pointer-events: all;
	}
	svg {
		path {
			transition-duration: 250ms;
			cursor: pointer;
			pointer-events: all;

			&:hover {
				fill: var(--color-main-3);
			}
		}
	}
}






