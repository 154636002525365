@font-face {
    font-family: "Montserrat-Regular";
    src: url("/assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-display: block;
}
