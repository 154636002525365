@use "sass:math";

.datepicker {
    font-size: 14rem;

    .datepicker-header,
    .datepicker-main,
    .datepicker-footer,
    .datepicker-picker,
    .datepicker-grid,
    .datepicker-view,
    .days {
        width: 100%;
    }

    .datepicker-main {
        margin-top: 10px;
    }

    .datepicker-cell,
    .datepicker-view .week {
        width: math.div(100%, 7);
        padding: 5px 10px;
    }

    .datepicker-cell {
        &.day {
            &:not(.disabled) {
                background-color: var(--color-main-4);
            }
        }
    }

    .datepicker-controls,
    .datepicker-grid,
    .datepicker-view,
    .datepicker-view .days-of-week {
        display: flex;
    }

    .days-of-week {
        background-color: var(--color-main-1);
        border-radius: 3px;
        min-height: 35px;
        display: flex;
        align-items: center;
    }

    .datepicker-grid {
        margin-top: 10px;
        flex-wrap: wrap;
    }

    .datepicker-dropdown {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10000;

        &.datepicker-orient-top {
            padding-top: 0;
        }
    }

    .datepicker-footer {
        background-color: var(--color-1);
    }
}

.datepicker-picker span {
    -webkit-touch-callout: none;
    border: 0;
    border-radius: 4px;
    cursor: default;
    display: block;
    text-align: center;
    user-select: none;
}

.datepicker-title {
    background-color: var(--color-1);
    box-shadow: inset 0 -1px 1px hsla(0, 0%, 4%, .1);
    font-weight: 700;
    padding: 5px;
    text-align: center
}

.datepicker-controls .button {
    align-items: center;
    background-color: var(--color-we);
    border-radius: 4px;
    box-shadow: none;
    color: var(--color-bk);
    cursor: pointer;
    display: inline-flex;
    font-size: 14rem;
    height: 35px;
    justify-content: center;
    line-height: 1;
    margin: 0;
    position: relative;
    text-align: center;
    vertical-align: top;
    white-space: nowrap
}

.datepicker-controls .button:active,
.datepicker-controls .button:focus {
    outline: none
}

.datepicker-controls .button:hover {
    border-color: var(--color-1);
    color: #363636
}

.datepicker-controls .button:focus {
    border-color: var(--color-main-5);
    color: #363636
}

.datepicker-controls .button:focus:not(:active) {
    box-shadow: 0 0 0 .125em rgba(50, 115, 220, .25)
}

.datepicker-controls .button:active {
    border-color: #4a4a4a;
    color: #363636
}

.datepicker-controls .button[disabled] {
    cursor: not-allowed
}

.datepicker-header .datepicker-controls .button {
    border-color: transparent;
    font-weight: 700
}

.datepicker-header .datepicker-controls .button:hover {
    background-color: var(--color-1);
}

.datepicker-header .datepicker-controls .button:focus:not(:active) {
    box-shadow: 0 0 0 .125em hsla(0, 0%, 100%, .25)
}

.datepicker-header .datepicker-controls .button:active {
    background-color: var(--color-1);
}

.datepicker-header .datepicker-controls .button[disabled] {
    box-shadow: none
}

.datepicker-footer .datepicker-controls .button {
    border-radius: 2px;
    font-size: 16rem;
    margin: calc(.375rem - 1px) .375rem;
    width: 100%
}

.datepicker-controls .view-switch {
    flex: auto
}

.datepicker-controls .next-btn,
.datepicker-controls .prev-btn {
    background-color: var(--color-1);
    width: 35px;
    height: 35px;
    padding-left: .375rem;
    padding-right: .375rem;
}

.datepicker-controls .view-switch {
    margin-left: 4px;
}

.datepicker-controls .next-btn {
    margin-left: 4px;
}

.datepicker-controls .next-btn.disabled,
.datepicker-controls .prev-btn.disabled {
    visibility: hidden
}

.datepicker-view .dow {
    width: math.div(100%, 7);
    font-size: 14rem;
    color: var(--color-we);
    font-weight: 700;
}

.datepicker-view .week {
    color: #b5b5b5;
    font-size: .75rem;
    width: 2.25rem
}

.datepicker-cell:not(.disabled):hover {
    background-color: var(--color-1);
    cursor: pointer
}

.datepicker-cell.focused:not(.selected) {
    background-color: var(--color-main-3);
    color: var(--color-bk);
}

.datepicker-cell.selected,
.datepicker-cell.selected:hover {
    background-color: var(--color-main-5);
    color: var(--color-bk);
    font-weight: 600;
}

.datepicker-cell.disabled {
    color: var(--color-17)
}

.datepicker-cell.next:not(.disabled),
.datepicker-cell.prev:not(.disabled) {
    color: #7a7a7a
}

.datepicker-cell.next.selected,
.datepicker-cell.prev.selected {
    color: #e6e6e6
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
    background-color: var(--color-1);
    border-radius: 0
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
    background-color: var(--color-1);
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
    background-color: var(--color-1);
}

.datepicker-cell.today:not(.selected) {
    background-color: var(--color-main-5);
}

.datepicker-cell.today:not(.selected):not(.disabled) {
    color: var(--color-we)
}

.datepicker-cell.today.focused:not(.selected) {
    background-color: var(--color-main-5)
}

.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
    background-color: var(--color-1);
    color: var(--color-we);
}

.datepicker-cell.range-end.focused:not(.selected),
.datepicker-cell.range-start.focused:not(.selected) {
    background-color: var(--color-1);
}

.datepicker-cell.range-start {
    border-radius: 4px 0 0 4px
}

.datepicker-cell.range-end {
    border-radius: 0 4px 4px 0
}

.datepicker-cell.range {
    background-color: var(--color-1);
    border-radius: 0
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
    background-color: var(--color-1);
}

.datepicker-cell.range.disabled {
    color: var(--color-1);
}

.datepicker-cell.range.focused {
    background-color: var(--color-1);
}

.datepicker-input.in-edit {
    border-color: var(--color-main-5)
}

.datepicker-input.in-edit:active,
.datepicker-input.in-edit:focus {
    box-shadow: 0 0 .25em .25em rgba(35, 102, 209, .2)
}
